import React from "react"
import Page from "../components/page.js"

export default () => {
  return (
    <Page>
      <div>At the New York Times:</div>
        <div>
        + <a
          href="https://www.nytimes.com/interactive/2024/05/03/us/ucla-protests-encampment-violence.html?unlocked_article_code=1.pU0.KrbY.uNidGQ5iXlaj&smid=url-share"
          target="_blank"
          rel="noreferrer"
        >
          How Counterprotesters at U.C.L.A. Provoked Violence, Unchecked for Hours
          </a>{" "}
          (2024)
        </div>
    
        <div>
        + <a
          href="https://www.nytimes.com/interactive/2024/02/01/world/middleeast/Israel-gaza-war-demolish.html?unlocked_article_code=1.aU0.RhFf.6_-ZiY4QDvtV&smid=url-share"
          target="_blank"
          rel="noreferrer"
        >
          Israel’s Controlled Demolitions Are Razing Neighborhoods in Gaza
          </a>{" "}
          (2024)
        </div>
    
        <div>
        + <a
          href="https://www.nytimes.com/interactive/2023/12/28/world/middleeast/jabaliya-gaza-strike-israel.html?unlocked_article_code=1.aU0.D27k.BNmnmYvYh6Dr&smid=url-share"
          target="_blank"
          rel="noreferrer"
        >
          What Happened in One of Israel's Deadliest Attacks in Gaza
          </a>{" "}
          (2023)
        </div>
      
      <div>At SITU Research:</div>

      <div>
        + <a
          href="https://situ.nyc/research/projects/sow-et-al-v-city-of-new-york-et-al"
          target="_blank"
          rel="noreferrer"
        >
          Sow, et al. v. City of New York, et al.
        </a>{" "}
        (2023)
      </div>

      <div>
        + <a
          href="https://situ.nyc/research/projects/death-at-the-station-russian-cluster-munition-attack-in-kramatorsk-ukraine"
          target="_blank"
          rel="noreferrer"
        >
          Death at the Station
        </a>{" "}
        (2023)
      </div>

      <div>
        + <a
          href="https://situ.nyc/research/projects/crime-scene-bucha"
          target="_blank"
          rel="noreferrer"
        >
          Crime Scene: Bucha
        </a>{" "}
        (2022)
      </div>

      <div>
        + <a
          href="https://situ.nyc/research/projects/codec-collaborative-tool"
          target="_blank"
          rel="noreferrer"
        >
          Codec: a collaborative event reconstruction tool
        </a>{" "}
        (2022)
      </div>

      <div>
        + <a
          href="https://situ.nyc/research/projects/operation-siloe-event-reconstruction"
          target="_blank"
          rel="noreferrer"
        >
          Operación Siloé Event Reconstuction
        </a>{" "}
        (2022)
      </div>

      <div>
        + <a
          href="https://situ.nyc/research/projects/after-the-strike"
          target="_blank"
          rel="noreferrer"
        >
          After the Strike
        </a>{" "}
        (2021)
      </div>

      <div>
        + <a
          href="https://situ.nyc/research/projects/charlotte-kettling-analysis"
          target="_blank"
          rel="noreferrer"
        >
          Charlotte Kettling Analysis
        </a>{" "}
        (2021)
      </div>

      <div>
        + <a
          href="https://situ.nyc/news/unitad-video-release-yazidi-iraq"
          target="_blank"
          rel="noreferrer"
        >
          UNITAD
        </a>{" "}
        (2021)
      </div>

      <div>
        + <a
          href="https://situ.nyc/research/projects/portland-protest-reconstruction"
          target="_blank"
          rel="noreferrer"
        >
          Portland June 30th Protest
        </a>{" "}
        (2020)
      </div>
      <div>
        + <a
          href="https://situ.nyc/research/projects/mott-haven-protest"
          target="_blank"
          rel="noreferrer"
        >
          The Trap
        </a>{" "}
        (2020)
      </div>
      <div>
        + <a
          href="https://situ.nyc/research/projects/tear-gas"
          target="_blank"
          rel="noreferrer"
        >
          Choking Dissent
        </a>{" "}
        (2020)
      </div>
      <div>
        + <a
          href="https://situ.nyc/research/projects/smokescreen-iraqs-use-of-militarygrade-tear-gas-grenades-to-kill-protesters"
          target="_blank"
          rel="noreferrer"
        >
          Smokescreen
        </a>{" "}
        (2020)
      </div>

      <div>
        + <a
          href="http://gieinicaragua-cartografia-violencia.org"
          target="_blank"
          rel="noreferrer"
        >
          Nicaragua: Mapping Violence in the Context of Protests
        </a>{" "}
        (2019)
      </div>

      <div>
        + <a
          href="https://theintercept.com/2023/06/12/icc-war-crimes-digital-evidence-reconstruction"
          target="_blank"
          rel="noreferrer"
        >
          ICC Al Hassan Case
        </a>{" "}
        (2019)
      </div>

      <div>Juliana vs US (2019)</div>
    </Page>
  )
}
